* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  padding: 0;
  margin: 0;
}

.card-body {
  min-height: 192px;
}

li {
  list-style: none;
}

td {
  font-size: 15px;
}

.text-pink {
  color: rgb(209, 35, 186);
}

.text-blue {
  color: blue;
}

.side-menu {
  position: fixed;
  background-color: #000;
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0px 30px 10px;
  transition: width 0.2s ease-in;
  overflow: auto;
}

.f-right {
  float: right;
}

.side-menu.inactive {
  width: 83px;
}

.top-section {
  position: relative;
}

.side-menu .logo {
  width: 140px;
  height: auto;
  overflow: hidden;
  display: inline-block;
}

.side-menu .logo img {
  max-width: 100%;
  max-height: 100%;
}

.toggle-menu-btn {
  font-size: 28px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
  cursor: pointer;
}

.side-menu.inactive .toggle-menu-btn {
  right: -60px;
}

.menu-item {
  font-size: 15px;
  display: block;
}

.tbl-100 {
  width: 100%;
}

.table-scrolling {
  display: block;
  width: 100%;
  height: 75vh;
  overflow-x: scroll;
  overflow-y: auto;
}

@media only screen and (min-width: 600px) {
  .logoForm {
    width: 30% !important;
  }
}

.menu-icon {
  display: inline-block;
}

.width-auto {
  width: auto;
}

.f-right {
  float: right;
}

.invalid-feedback {
  text-transform: capitalize;
}

.first-latter-capital {
  text-transform: capitalize;
}

.open-menu-tab {
  width: calc(100% - 200px);
}

.crossbtn {
  font-size: 21px;
  background: red;
  border-radius: 50%;
  padding: 5px 8px 7px 8px;
}

.listmenubtn {
  font-size: 21px;
  background: green;
  border-radius: 50%;
  padding: 5px 8px 7px 8px;
}

.close-menu-tab {
  width: 100%;
  z-index: -1;
}

.content {
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

.content-area {
  min-height: 100vh;
  transition: all 0.3s;
  top: 10%;
  right: 0;
}

/* .content-active {
  width: 94%;
  position: absolute;
  left: 6%;
  overflow: hidden;
} */

.dropdown-menu[data-bs-popper] {
  left: -80%;
}

.mt-extra {
  margin-top: 5.25rem !important;
}

/* .card-width{
    width: 21rem;
} */
.thankyou-title {
  text-align: center;
  padding: 100px 0px;
}

.margin-left {
  margin-left: 25%;
}

.margin-m-left {
  margin-left: 5%;
}

/* //login form Css */
a {
  text-decoration: none;
}

.login-page {
  width: 100%;
  height: 100vh;
  display: inline-block;
  display: flex;
  align-items: center;
}

.form-right i {
  font-size: 100px;
}

.nav-link {
  font-size: 16px;
  padding: 0px;
}

hr {
  margin: 0.5rem 0;
}

.bg-side {
  background-color: #000;
}

.display-n {
  display: none;
}

.hide-side-menu {
  display: none;
}

.active {
  background: #375181;
  padding: 2px;
  border-radius: 8px 0px 0px 8px;
}

/* .sideMenuLinks{
  padding: 6px 0px;
} */
.nav-link {
  color: #fff;
}

.sub-tbl tr,
th,
td {
  border-style: solid;
  border-width: 1px;
  padding: 0px 2px;
}

.over-col-size {
  width: 14%;
}

.table>:not(caption)>*>* {
  padding: 0.2rem 0.2rem !important;
}

/* table th {
  font-size: 13px;
  line-height: 13px;
} */
.text-align-end {
  text-align: end;
}

.my-profile-content p {
  font-size: 20px;
}

.my-profile-content {
  box-shadow: 1px 2px 5px 0px;
}

@media screen and (max-width: 1100px) {
  table {
    overflow-x: auto;
    overflow-y: auto;
    display: block;
  }

}

/* //responsive design */
@media (min-width: 320px) and (max-width: 480px) {
  .p-mobile {
    margin-top: 10px;
    margin-left: -50px;
  }

  .mbt-phone {
    margin-left: -30px;
    margin-top: 10px;
  }

  .mb-phone {
    margin-bottom: 10px;
  }

  .side-menu {
    width: 33%;
  }

  .side-menu .logo {
    width: 50px;
  }

  .content {
    width: calc(67%);
  }

  .ds {
    display: none;
  }

  .nav-link {
    font-size: 15px;
    padding: 0px;
  }

  .nav.fs-3.mt-1.flex-column {
    margin-left: -8px;
  }

}

/* //responsive design */
.notFountPage {
  text-align: center;
  width: 50%;
  margin: auto;
  margin-top: 30vh;
  background: #ccc;
  padding: 50px 25px;
}

.report-card button {
  bottom: 12px;
}

.table-img {
  height: 50px;
}

@media print {
  .print-hidden {
    visibility: hidden;
  }

  body {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  .table-scrolling {
    display: initial;
    width: 100%;
    height: auto;
    overflow-x: initial;
    overflow-y: auto;
  }

  .content {
    width: 100% !important;
  }
}

.float-right {
  float: right;
}

/* taginput */
.tags-input-container {
  border: 1px solid #ced4da;
  padding: .5em;
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .5em;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: .5em .75em;
  border-radius: 20px;
}

.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: .5em 0;
  border: none;
  outline: none;
}

/* tag input end */